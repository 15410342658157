<!-- eslint-disable max-len -->
<template>
    <div>
        <LoadingComponent :isLoading="loading" />
        <div class="shadow-lg rounded-lg bg-white flex items-center p-5 mb-3">
            <div class="grid grid-cols-12 gap-4">
                <div class="col-span-5">
                    <label class="text-gray-400">
                        用戶 手機
                        <div class="flex">
                            <div class="phone-select">
                                <el-select v-model="filterOpts.phone_country" size="small" placeholder="请选择">
                                    <el-option v-for="item in phoneOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                                </el-select>
                            </div>
                            <div>
                                <el-input v-model="filterOpts.phone" size="small" clearable> </el-input>
                            </div>
                        </div>
                    </label>
                </div>
                <div class="col-span-3 flex items-end">
                    <button class="orange-btn-800 md:text-sm text-xs mr-1 w-[73px] h-[30px] flex-1" @click="goSearch">搜尋</button>
                    <button class="black-btn-outline md:text-sm text-xs w-[73px] h-[30px] flex-1" @click="reset">重置</button>
                </div>
            </div>
        </div>
        <div class="overflow-x-auto bg-white">
            <table class="myTable">
                <thead>
                    <tr>
                        <th>手機</th>
                        <th>驗證碼</th>
                        <th>有效期限</th>
                        <th>刪除</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in datas" :key="index" class="text-center duration-200 transition">
                        <td>{{ item.phone }}</td>
                        <td>{{ item.code }}</td>
                        <td>{{ item.expired_at | formatDateTime }}</td>
                        <td class="flex justify-center cursor-pointer">
                            <div
                                class="border border-red-500 rounded-lg hover:bg-red-500 hover:text-white duration-500 text-red-500 p-2 max-w-[35px] min-h-[35px] flex justify-center items-center flex-1"
                                @click="openDialog(item.phone)">
                                <i class="far fa-trash-alt"></i>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <!-- 確認是否刪除簡訊驗證碼 -->
        <my-dialog :showDialog="showDialog" :customClass="'top-1/4 z-[70]'" :customBlackBgClass="'z-[60]'" @onCloseDialog="closeDialog">
            <div class="w-full md:p-10 p-5 bg-white rounded-lg text-sm">
                <p>確認刪除此手機簡訊驗證碼？</p>
                <div class="flex justify-center mt-5">
                    <button
                        :disabled="loading"
                        class="border border-black px-3 py-0.5 rounded mr-5 disabled:bg-gray-300 disabled:cursor-not-allowed disabled:text-white disabled:border-gray-300"
                        @click.prevent="closeDialog">
                        關閉
                    </button>
                    <button
                        v-loading="loading"
                        :disabled="loading"
                        class="bg-red-500 px-3 py-0.5 rounded text-white disabled:bg-gray-300 disabled:cursor-not-allowed"
                        @click.prevent="deleteCode(phone)">
                        確認
                    </button>
                </div>
            </div>
        </my-dialog>
    </div>
</template>

<script>
// 旋轉動畫組件
import LoadingComponent from "@/components/Loading.vue";
// options phone
import { phoneOptions } from "@/config/optionsConfig.js";
import myDialog from "@/components/Dialog.vue";
// init search Filter options
const defaultFilterOpts = {
    phone_country: "886",
    phone: "",
};
export default {
    name: "SmsCode",
    components: {
        LoadingComponent,
        myDialog,
    },
    computed: {
        // 統整 getlist api filters
        filterData() {
            const phone = this.filterOpts.phone ? `${this.filterOpts.phone_country}${this.filterOpts.phone}` : "";
            return {
                phone,
            };
        },
        // 手機國碼
        phoneOptions() {
            return phoneOptions;
        },
    },
    data() {
        return {
            loading: false,
            datas: [],
            filterOpts: { ...defaultFilterOpts },
            phone: null,
            showDialog: false,
        };
    },
    methods: {
        // 取得有發送驗證碼的手機
        async getList() {
            this.loading = true;
            try {
                const { data } = await this.$api.GetAuthCodesApi(this.filterData.phone);
                console.log(data);
                this.datas = [{ ...data }];
                // return { data, status: status };
            } catch (err) {
                console.log(err);
                this.$message({
                    type: "error",
                    message: "查無此手機驗證碼",
                });
            } finally {
                this.loading = false;
            }
        },
        // 搜尋手機號碼
        goSearch() {
            this.getList();
        },
        // 重置資料
        reset() {
            this.datas = [];
            this.filterOpts = { ...defaultFilterOpts };
        },
        /**
         * 開啟刪除驗證碼確認彈窗
         * @param { type String(字串) } phone 手機號碼
         */
        openDialog(phone) {
            this.showDialog = true;
            this.phone = phone;
        },
        /**
         * 關閉刪除驗證碼確認彈窗
         *
         */
        closeDialog() {
            this.showDialog = false;
            this.phone = null;
        },
        /**
         * 刪除驗證碼
         * @param { type String(字串) } phone 手機號碼
         */
        async deleteCode(phone) {
            this.loading = true;
            try {
                await this.$api.DeleteAuthCodeApi(phone);
                this.showDialog = false;
                this.datas = [];
                this.phone = null;
                this.$message({
                    type: "success",
                    message: "刪除簡訊驗證碼成功",
                });
            } catch (err) {
                console.log(err);
                this.$message({
                    type: "error",
                    message: "刪除簡訊驗證碼失敗",
                });
            } finally {
                this.loading = false;
            }
        },
    },
    watch: {
        "filterOpts.phone"(val) {
            if (this.$isEmpty(val)) {
                return;
            }
            if (val[0] == 0 && val[1] == 9) {
                this.filterOpts.phone = val.substring(1);
                return;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.myTable {
    width: 100%;
    tbody {
        tr .show-ellipsis {
            max-width: 100px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}

label {
    color: #757575;
    font-size: 16px;
    font-weight: 400;
    line-height: 34px;
}

.phone-select {
    width: 150px;
    margin-right: 5px;
}

::v-deep .el-input__inner {
    max-width: 100%;
}

::v-deep .el-range-separator {
    width: 20px;
}
</style>
